const colors = {
  primary: "#F65A83",
  secondary: "#C21010",
  black: "#000",
  white: "#fff",
  dark_primary: "#0D0104",
  blue: "#0077b5"
};

export default colors;
